import { useEvent } from 'react-use';
import { unloadApplication } from 'single-spa';

const EVENT_NAMES = Object.freeze({
  BEFORE_ROUTING: 'single-spa:before-routing-event',
});

const APP_STATUSES = Object.freeze({
  NOT_MOUNTED: 'NOT_MOUNTED',
});

function getAppNameByStatus(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const unloadTheNotMountedAppAfterRedirect = async ({ detail: { newAppStatuses } }) => {
  const unmountedApp = getAppNameByStatus(newAppStatuses, APP_STATUSES.NOT_MOUNTED);
  unmountedApp && (await unloadApplication(unmountedApp));
};

export function useSubAppLifeCycle() {
  useEvent(EVENT_NAMES.BEFORE_ROUTING, unloadTheNotMountedAppAfterRedirect);
}
